import React from "react";
import ReactDOM from "react-dom";
import ReactResizeDetector from 'react-resize-detector';

import {
  Button,
  Input,
  AutoComplete,
  DatePicker,
  Radio,
  InputNumber,
  Typography,
  Row,
  Alert,
  ConfigProvider,
  Col
} from "antd";
import { SwapOutlined, EnvironmentFilled } from "@ant-design/icons";

import en_GB from 'antd/lib/locale-provider/en_GB';

import moment from "moment";
import 'moment/locale/en-gb';  // important!

import "antd/dist/antd.css";
import "./index.css";

moment.locale('en-gb');  // important!

const { Text } = Typography;
const { RangePicker } = DatePicker;

const calcRows = (width) => {
  if(width >= 900) return 1
  if(width >= 500) return 2
  return 3
}
const renderTitle = (title: string) => {
  return <span>{title}</span>;
};

const renderItem = item => {
  return {
    value: item.name,
    label: <div>{item.name}</div>
  };
};

// const options = [
//   {
//     label: renderTitle("Europe"),
//     options: [renderItem("Berlin", 10000), renderItem("Munich", 10600)]
//   }
// ];

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const destinations = JSON.parse(
  document.querySelector("#destinations").innerHTML
).data

destinations.forEach((d) => {
  d.destination_ids = (d.destination_ids || '').split(',').map(s => s)
})

const regions = groupBy(destinations, "region");

class App extends React.Component {

  optionsFor = (v, otherCity) => {
    // console.log(`other city is ${otherCity ? otherCity.name : 'undefined'} v is ${v}`)
    const options = Object.keys(regions).map(region => {
      return {
        label: renderTitle(region),
        options: Object.values(regions[region])
          .filter(o => o.name.toUpperCase().indexOf(v.toUpperCase()) !== -1)
          .filter(o => {
            if(otherCity) {
              // console.log(o.destination_ids.indexOf('' + otherCity.id) > -1  )
              return o.destination_ids.indexOf('' + otherCity.id) > -1

            } else {
              return true

            }
          })
          .sort((a, b) => a.name.localeCompare( b.name))
          .map(city => renderItem(city))
      }
    }).filter(o => o.options.length > 0)
    // console.log(options)
    return options
  };

  fromOptionsFor = v => {
    // let city
    // if(this.state){
    //   city = destinations.find(city => city.name === this.state.to)
    // }
    return this.optionsFor(v) // , city
  }

  toOptionsFor = (v, otherCity = '') => {
    let city
    if(this.state){
      city = destinations.find(city => city.name === otherCity)
    }
    // console.log({v, city})
    return this.optionsFor(v, city)
  }

  state = {
    passengers: 1,
    oneway: 1,
    from: this.props.from, //
    to: this.props.to, //
    basehref: this.props.basehref || "http://www.example.com",
    append: this.props.append || "",
    toClicked: false,
    fromClicked: false,
    depart: new Date(),
    arrive: new Date(),
    fromOptions: this.optionsFor(''),
    toOptions: this.optionsFor(''),
    dismissed: true
  };

  checkDestinations = () => {
    let from = destinations.find(d => d.name.toUpperCase() === this.state.from.toUpperCase())
    let to = destinations.find(d => d.name.toUpperCase() === this.state.to.toUpperCase())
    if(to && from && to.destination_ids.indexOf('' + from.id) > -1){
      document.location = this.url()
      // this.url()
    } else {
      this.setState({dismissed: false})
    }
  }
  val = (s) => {
    let city = destinations.find(d => d.name.toUpperCase() === s.toUpperCase())
    // console.log(s,city)
    return city ? city.value : s
  }
  url = () => {
    var ret = `${this.state.basehref}/${this.val(this.state.from)}/${
      this.val(this.state.to)
      }?date=${moment(this.state.depart).format("YYYY-MM-DD")}`;
    if (!this.state.oneway) {
      ret += `&date2=${moment(this.state.arrive).format("YYYY-MM-DD")}`;
    }
    ret += `&people=${this.state.passengers}`;
    ret += `${this.state.append}`;
    return ret;
  };

  onChange = e => {
    // console.log("radio checked", e.target.value);
    this.setState({
      oneway: e.target.value
    });
  };

  completes() {
    return <div>
      <div>
        <Text>From</Text>
        <Text style={{ marginLeft: this.state.rows === 1 ? 'calc(50% - 10px)' : '50%' }}>To</Text>
      </div>
      <AutoComplete
        dropdownClassName="certain-category-search-dropdown"
        dropdownMatchSelectWidth={300}
        options={this.state.fromOptions}
        value={this.state.from}
        style={{ width: "45%" }}
        onChange={v => {
          let fromOptions = this.fromOptionsFor(v)
          let toOptions = this.toOptionsFor(this.state.to, v)
          // console.log({x: v, o: toOptions[0].options})

          this.setState({
            toOptions,// fromOptions: this.fromOptionsFor(v),
            fromOptions, //, this.state.to
            from: v
          });
        }}
        // onDropdownVisibleChange	={v => {
        //   let fromOptions = this.fromOptionsFor(this.state.from) // { fromOptions });
        //   // setTimeout(() => {
        //     // console.log({ fromOptions, c: 'vc' })
        //     this.setState({ fromOptions })
        //     // }       , 50)
        // }}

        onClick={() => {

          if(!this.state.fromClicked){
            // console.log('!')
            this.setState({
              to: '',
              from: '',
              toClicked: true,
              fromClicked: true,
              toOptions: this.optionsFor(''),
              fromOptions: this.optionsFor('')
            })
          }
        }}
      >
        <Input size="large" placeholder={this.props.from} prefix={<EnvironmentFilled />} />
      </AutoComplete>
      <Button
        type="primary"
        icon={<SwapOutlined />}
        size="large"
        style={{ width: "10%" }}
        onClick={() => {
          var from = this.state.to
          var to = this.state.from

          let toOptions = this.toOptionsFor(to, from)
          let fromOptions = this.fromOptionsFor(from)
          // console.log({from: this.state.from, o: toOptions[0].options})

          this.setState({
            to, from,
            toOptions,
            fromOptions
          });

        }}
      />
      <AutoComplete
        dropdownClassName="certain-category-search-dropdown"
        dropdownMatchSelectWidth={300}
        options={this.state.toOptions}
        value={this.state.to}
        style={{ width: "45%" }}
        onChange={v => {

          let toOptions = this.toOptionsFor(v, this.state.from)
          let fromOptions = this.fromOptionsFor(this.state.from)
          // console.log({from: this.state.from, o: toOptions[0].options})

          this.setState({
            toOptions,
            fromOptions,
            to: v
          });
        }}
        // onDropdownVisibleChange	={v => {
        //   // // console.log(`this.toOptionsFor(${this.state.from})`, this.toOptionsFor(this.state.from))
        //   this.setState({
        //     toOptions: this.toOptionsFor(this.state.to, this.state.from ),

        //   });
        // }}
        onClick={() => {
          if(!this.state.toClicked){
            // console.log('!')
            this.setState({
              to: '',
              from: '',
              toClicked: true,
              fromClicked: true,
              toOptions: this.optionsFor(''),
              fromOptions: this.optionsFor('')
            })
          }
        }}
      >
        <Input size="large" placeholder={this.props.to} prefix={<EnvironmentFilled />} />
      </AutoComplete>
    </div>
  }
  departureOffset(){
    return 'calc(44% - 40px)'
    // switch(this.state.rows){
    //   case 1: return 'calc(40% - 20px)'
    //   case 2: return 'calc(44% - 40px)'
    //   case 3: return 'calc(40% - 20px)'

    // }
  }
  pickers() {
    return <div>
      <div>
        <Text>Departure</Text>
        {this.state.oneway ? (
          ""
        ) : (
            <Text style={{ marginLeft: this.departureOffset()  }}>Return</Text>
          )}
      </div>
      {this.state.oneway ? (
        <DatePicker
          size="large"
          value={moment(this.state.depart)}
          format="ddd DD MMM"
          style={{ width: "100%", before: 'xxx' }}
          prefix={<EnvironmentFilled />}
          locale={en_GB}
          disabledDate={(current) => {
            // Can not select days before today and today
            return current && current.valueOf() < Date.now();
          }}
          onChange={m => {
            this.setState({
              depart: m ? m.toDate() : undefined
            });
          }}
        />
      ) : (
          <RangePicker
            size="large"
            value={[moment(this.state.depart), moment(this.state.arrive)]}
            format="ddd DD MMM"
            style={{ width: "100%" }}
            separator=""
            disabledDate={(current) => {
              // Can not select days before today and today
              return current && current.valueOf() < Date.now();
            }}
            onChange={a => {
              // console.log(a);
              this.setState({
                depart: a && a[0] ? a[0].toDate() : undefined,
                arrive: a && a[1] ? a[1].toDate() : undefined
              });
            }}
          />
        )}
    </div>
  }
  people() {
    return <div>
      <div>
        <Text>People</Text>
      </div>
      <InputNumber
        min={1}
        max={99}
        defaultValue={1}
        onChange={(i) => {this.setState({passengers: i})}}
        size="large"
        style={{ width: "100%", minWidth: 50 }}
      />
    </div>
  }
  button() {
    return <div>
      <div>
        <Text>&nbsp;</Text>
      </div>
      <Button
        type="primary"
        size="large"
        style={{ width: "100%" }}
        onClick={this.checkDestinations}
      >
        Search
            </Button>
    </div>
  } // https://book.bustickets.ph/en/travel/laoag/manila?date=05-14-2020&date2=06-30-2020&people=1&sub_id=btp

  render() {
    return (
      <div className="App">
        <ReactResizeDetector handleWidth handleHeight onResize={(width, height) => {
          let rows = calcRows(width)
          if(this.state.rows !== rows){
            this.setState({ rows })
          }
        }} />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Radio.Group onChange={this.onChange} value={this.state.oneway}>
              <Radio value={1} size="large">
                One Way
              </Radio>
              <Radio value={0} size="large">
                Round Trip
              </Radio>
            </Radio.Group>
          </Col>
        </Row>

        {(this.state.rows === 1) ? <Row gutter={[16, 16]}>
          <Col span={11}>
            {this.completes()}
          </Col>
          <Col span={8}>
            {this.pickers()}
          </Col>
          <Col span={2}>
            {this.people()}
          </Col>
          <Col span={3}>
            {this.button()}
          </Col>
        </Row> : this.state.rows === 2 ? <div><Row gutter={[16, 16]}>
          <Col span={24}>
            {this.completes()}
          </Col>
        </Row>
            <Row gutter={[16, 16]}>
              <Col span={14}>
                {this.pickers()}
              </Col>
              <Col span={4}>
                {this.people()}
              </Col>
              <Col span={6}>
                {this.button()}
              </Col>
            </Row></div> : <div><Row gutter={[16, 16]}>
          <Col span={24}>
            {this.completes()}
          </Col>
        </Row>
            <Row gutter={[16, 16]}>

              <Col span={24}>
                {this.pickers()}
              </Col>
              </Row>
              <Row gutter={[16, 16]}>
              <Col span={6}>
                {this.people()}
              </Col>
              <Col span={18}>
                {this.button()}
              </Col>
            </Row></div>}

        {(this.state.dismissed) ? '' : <Alert
          message="There are no buses that connect those destinations."
          type="error"
          closable
          onClose={() => this.setState({dismissed: true})}
        /> }


        {/* {this.url()}*/}
        {/* {JSON.stringify(this.state)} */}
      </div>
    );
  }
}

var root = document.querySelector("#root");
ReactDOM.render(
  <ConfigProvider locale={en_GB}>


  <App
    to={root.getAttribute("data-to")}
    from={root.getAttribute("data-from")}
    basehref={root.getAttribute("data-basehref")}
    append={root.getAttribute("data-append")}
  />
  </ConfigProvider>,
  root
);

